import React from 'react';
import { Package } from '@byhuz/huz-ui-project';

import {GoogleSearch,initMockup,MockupProvider} from '@bygd/gd-html5-search';

import { theme } from '@byhuz/huz-ui-cindy/dist/google';

//const apiBase = 'https://api.search.project.huz.byorbit.com';
const apiBase = 'https://html5-portal-api.gamedistribution.com';

Package.imports = {
  ...Package.imports,
  project: {
    queryApi: `${apiBase}/graphql`,
    mostpoint: `${apiBase}/most`,    
    azerionconnect: {
      realm: 'azerion',
      clientId: 'GDSearch',
      url: 'https://idp.azcdev.com/auth/',
    },
  },
};

initMockup();
//const stories = storiesOf('index', module);

export const MySearch = ()=>{
    return(
        <MockupProvider theme={theme}>
            <GoogleSearch />
        </MockupProvider>        
      );
}


