import React from 'react';
//import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { App } from './App';

// document.body.appendChild(
// 	Object.assign(document.createElement(`div`), { id: 'root' })
// );



//const rootEl = document.getElementById('root');

//const root = createRoot(rootEl);

//root.render(<App/>);


ReactDOM.render(<App/>,root);